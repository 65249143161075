<template>
  <div class="insurance-program-patients">
    <b-row class="mb-4">
      <b-col class="w-100">
        <div class="d-flex">
          <b-input-group class="bg-white rounded">
            <b-form-input
              v-model="filter.query"
              type="search"
              class="w-75"
              placeholder="Введите имя пациента"
            />

            <b-form-input
              v-model="filter.policyId"
              type="search"
              class="w-25"
              placeholder="Введите номер полиса"
            />
          </b-input-group>

          <b-button
            id="popover-filter"
            variant="outline-primary"
            :type="$const.PRIMARY_BUTTON"
            class="ml-2"
            @click="togglePopover"
          >
            <b-icon icon="filter" />
          </b-button>

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="rounded ml-2 border-secondary"
            @click="patientsFetch(true)"
          >
            <b-icon icon="search" />
          </b-button>

          <base-button
            v-if="false"
            :type="$const.SECONDARY_BUTTON"
            class="load-excel-button"
          >
            <span>
              Скачать Excel
            </span>
            <icon-excel
              class="crm-icon-excel"
            />
          </base-button>
        </div>
      </b-col>
    </b-row>
    <b-popover
      custom-class="mt-4 insurance-subprogram-filter-popover"
      :show.sync="filter.isPopoverOpen"
      target="popover-filter"
      placement="bottom"
    >
      <div class="font-weight-bold">
        Период
      </div>

      <div class="crm-filter-popover d-flex flex-column">
        <div class="crm-datepickers-container">
          <div>
            <div class="crm-datepicker-caption mb-2">
              Дата начала
            </div>
            <base-date-picker
              v-model="filter.policyStartDate"
              class="crm-datepicker"
            />
          </div>

          <div class="divider">
            <div class="divider-row" />
          </div>

          <div>
            <div class="crm-datepicker-caption mb-2">
              Дата окончания
            </div>
            <base-date-picker
              v-model="filter.policyEndDate"
              class="crm-datepicker"
              placement="bottom-end"
            />
          </div>
        </div>

        <base-checkbox
          v-model="filter.hasMobileAccount"
          class="crm-checkbox mt-3"
        >
          Наличие приложения
        </base-checkbox>
        <base-checkbox
          v-model="filter.hasConsultations"
          class="crm-checkbox mt-1"
        >
          Наличие консультаций
        </base-checkbox>
        <base-checkbox
          v-model="filter.hasActivePolicy"
          class="crm-checkbox mt-1"
        >
          Активация полиса
        </base-checkbox>
      </div>
    </b-popover>
    <async-list
      class="patient-list"
      :is-all-items-loaded="isAllItemsLoaded"
      :without-scrollbar="true"
      @load-more-items="loadMoreItems"
    >
      <div
        v-if="!totalCount && isAllItemsLoaded"
        class="crm-container"
      >
        <span
          class="page-subtitle"
        >
          Ничего не найдено
        </span>
      </div>

      <div
        v-if="totalCount"
        class="crm-container"
      >
        <div class="result-row">
          <span
            class="result-title"
          >
            Результаты поиска
          </span>

          <div
            v-if="subProgramInfo"
            class="result-count-wrapper"
          >
            <span class="result-count">
              Найдено полисов со скаченным приложением: {{ subProgramInfo.downloadedAppCount }}
            </span>
            <span class="result-count">
              Найдено полисов с полученными консультациями: {{ subProgramInfo.hadConsultationCount }}
            </span>
            <span class="result-count">
              Найдено активированных полисов: {{ subProgramInfo.activatedPolicyCount }}
            </span>
          </div>
        </div>
      </div>

      <patient-info
        v-for="(patient, index) in patients"
        :key="index"
        :patient="patient"
      />
    </async-list>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
} from '@/components/base';

import AsyncList from '@/components/AsyncList';
import PatientInfo from '@/components/Patients/PatientInfo';

import IconExcel from 'assets/images/exel_icon.svg';
import { formatISO } from '@evd3v/date-fns';

export default {
  name: 'InsuranceSubprogramPatients',
  components: {
    BaseButton,
    BaseDatePicker,
    BaseCheckbox,
    AsyncList,
    PatientInfo,
    IconExcel,
  },
  props: {
    subprogramId: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      isAllItemsLoaded: false,
      isLoading: false,
      limit: 15,
      filter: {
        query: '',
        hasMobileAccount: false,
        hasConsultations: false,
        hasActivePolicy: false,
        policyStartDate: null,
        policyEndDate: null,
        isPopoverOpen: false,
        policyId: null,
      },
      patients: [],
    };
  },
  computed: {
    totalCount() {
      return this.patients.length;
    },
    subProgramInfo() {
      return this.$store.state.Insurance.insuranceSubprogramsInfo;
    },
  },
  methods: {
    async patientsFetch(clear) {
      if (this.isLoading) return;
      if (!clear && this.isAllItemsLoaded) return; // костыль на остановку запроса, так как функция вызывается вне компонента и дублирует пациентов
      if (clear) this.patients = [];

      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = this.totalCount;
      const prevQuery = this.filter.query;
      const requestObject = {
        skip: prevTotal,
        take: this.limit,
        query: prevQuery,
        clear: !!clear,
        save: true,
        filter: {
          ...this.filter,
          subProgramId: this.subprogramId,
          policyStartDate: this.filter.policyStartDate && formatISO(this.filter.policyStartDate),
          policyEndDate: this.filter.policyEndDate && formatISO(this.filter.policyEndDate),
        },
      };

      try {
        const actionType = requestObject.filter.isDeleted ? 'PATIENTS_DELETED_FETCH' : 'PATIENTS_FETCH';
        const patients = await this.$store.dispatch(this.$types[actionType], requestObject);
        this.patients = [...this.patients, ...patients];

        this.$store.dispatch(this.$types.SUBPROGRAM_INFO_FETCH, {
          subProgramId: this.subprogramId,
        });

        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.filter.isPopoverOpen = false;
        this.isLoading = false;

        if (this.filter.query !== prevQuery) this.patientsFetch(true);
      }
    },
    loadMoreItems() {
      this.patientsFetch(false);
    },
    togglePopover() {
      this.filter.isPopoverOpen = !this.filter.isPopoverOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-program-patients {
  width: 100%;
}

.result-title {
  @extend .page-title;
  margin-right: auto;
  margin-bottom: 10px;
}

::v-deep.crm-icon-search:hover {
  cursor: pointer;
  path {
    fill: $blue;
  }
}

::v-deep.crm-info-wrapper {
  padding-top: 10px;
  padding-bottom: 30px;
}

.patient-list {
  min-height: 50px;
  box-sizing: border-box;

}

.result-row {
  flex: 1;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  flex-flow: wrap;
  width: 100%;
}

.result-count-wrapper{
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
  color: #707070;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}

.crm-datepickers-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.load-list-button {
  margin-left: 20px;
  width: unset;
  padding: 0 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  align-self: stretch;

  .crm-icon-reload {
    margin-right: 15px;
  }
}

.load-excel-button {
  margin-left: 20px;
  width: unset;
  padding: 0 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  align-self: stretch;
  background: transparent;

  .crm-icon-excel {
    margin-left: 15px;
  }
}
</style>

<style>
.insurance-subprogram-filter-popover {
  max-width: 400px;
}
</style>
