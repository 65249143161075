import { render, staticRenderFns } from "./InsuranceSubprogramPatients.vue?vue&type=template&id=ce4ca13c&scoped=true"
import script from "./InsuranceSubprogramPatients.vue?vue&type=script&lang=js"
export * from "./InsuranceSubprogramPatients.vue?vue&type=script&lang=js"
import style0 from "./InsuranceSubprogramPatients.vue?vue&type=style&index=0&id=ce4ca13c&prod&lang=scss&scoped=true"
import style1 from "./InsuranceSubprogramPatients.vue?vue&type=style&index=1&id=ce4ca13c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce4ca13c",
  null
  
)

export default component.exports