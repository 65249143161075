<template>
  <div class="insurance-company">
    <custom-scrollbar>
      <div class="insurance-company-inner">
        <div class="crm-container">
          <loading v-if="isLoading">
            Загрузка подпрограммы
          </loading>

          <template v-else>
            <div class="crm-container-title">
              <div class="crm-title">
                <div
                  class="crm-title-link"
                  @click="() => this.$router.go(-1)"
                >
                  <b-icon
                    icon="arrow-left"
                    font-scale="1.3"
                    variant="primary"
                  />
                </div>

                <span>
                  {{ company.name }} / {{ program.name }} / {{ subprogram.name }}
                </span>
              </div>
            </div>

            <div class="program-detail">
              <div class="d-flex justify-content-between align-items-start">
                <div class="program-title">
                  <div>{{ subprogram.name }}</div>
                  <div
                    v-if="!subprogram.isActive"
                    class="closed-label"
                  >
                    Закрыто
                  </div>
                </div>

                <div class="d-flex">
                  <b-button
                    v-if="checkFeatureAccess({
                      name: 'Кнопка редактирование подпрограммы',
                      url: '/insurance-companies/:company/programs/:program/subprograms/:subprogram',
                    })"
                    v-b-tooltip.hover
                    variant="link"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    title="Редактировать"
                    class="p-2"
                    @click="$router.push(`/insurance-companies/${company.id}/programs/${program.id}/subprograms/${subprogram.id}/edit`)"
                  >
                    <b-icon icon="pencil" />
                  </b-button>

                  <b-button
                    v-if="checkFeatureAccess({
                      name: 'Кнопка скрытия подпрограммы',
                      url: '/insurance-companies/:company/programs/:program/subprograms/:subprogram',
                    })"
                    v-b-tooltip.hover
                    variant="link"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    :title="subprogram.isHidden ? 'Убрать из скрытых' : 'Скрыть подпрограмму'"
                    class="ml-2 p-2"
                    @click="hideOrShowAgreeModal"
                  >
                    <b-icon
                      v-if="subprogram.isHidden"
                      icon="eye"
                    />
                    <b-icon
                      v-else
                      icon="eye-slash"
                    />
                  </b-button>
                </div>
              </div>

              <div
                v-if="!subprogram.isActive"
                class="deactivation-info"
              >
                <div><span>Причина деактивации:</span> {{ deactivationReason }}</div>
                <div style="margin-top: 10px">
                  <span>Комментарий к деактивации:</span> {{ subprogram.commentDeactivation }}
                </div>
              </div>

              <div class="program-info-row">
                <div class="program-info-block">
                  <div class="program-info-title">
                    Страховая компания
                  </div>
                  <div class="program-info-text">
                    {{ company.name }}
                  </div>
                </div>
                <div class="program-info-block">
                  <div class="program-info-title">
                    Программа
                  </div>
                  <div class="program-info-text">
                    {{ program.name }}
                  </div>
                </div>
                <!--                <div class="program-info-block">-->
                <!--                  <div class="program-info-title">-->
                <!--                    Срок действия полиса-->
                <!--                  </div>-->
                <!--                  <div-->
                <!--                    v-if="subprogram.policyPeriod"-->
                <!--                    class="program-info-text"-->
                <!--                  >-->
                <!--                    {{ periodText }}-->
                <!--                  </div>-->
                <!--                  <div-->
                <!--                    v-else-->
                <!--                    class="program-info-text"-->
                <!--                  >-->
                <!--                    —-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="program-info-block">
                  <div class="program-info-title">
                    Возрастные ограничения
                  </div>
                  <div class="program-info-text">
                    {{
                      subprogram.ageRestrictions !== null
                        ? subprogram.ageRestrictions.lowLimit + ' - ' + subprogram.ageRestrictions.highLimit + ' лет'
                        : '—'
                    }}
                  </div>
                </div>
                <div class="program-info-block">
                  <div class="program-info-title">
                    Лимит стоимости консультации
                  </div>
                  <div class="program-info-text">
                    {{ subprogram.limitingConsultationCost || '—' }}
                  </div>
                </div>
                <div class="program-info-block" />
              </div>

              <div class="program-info-row">
                <div class="program-info-block">
                  <div class="program-info-title">
                    Стоимость прикрепления
                  </div>
                  <div class="program-info-text">
                    {{ subprogram.attachmentCost !== null ? subprogram.attachmentCost + ' ₽' : '—' }}
                  </div>
                </div>
                <div class="program-info-block">
                  <div class="program-info-title">
                    Страховая выплата
                  </div>
                  <div class="program-info-text">
                    {{ subprogram.insurancePayment !== null ? subprogram.insurancePayment + ' ₽' : '—' }}
                  </div>
                </div>
                <div class="program-info-block">
                  <div class="program-info-title">
                    Сумма страхового покрытия
                  </div>
                  <div class="program-info-text">
                    {{ subprogram.insuranceCoverSum !== null ? subprogram.insuranceCoverSum + ' ₽' : '—' }}
                  </div>
                </div>
                <div class="program-info-block">
                  <div class="program-info-title">
                    Срок франшизы
                  </div>
                  <div class="program-info-text">
                    {{ subprogram.franchiseTerm !== null ? subprogram.franchiseTerm + ' день/дней' : '—' }}
                  </div>
                </div>
                <div class="program-info-block" />
              </div>

              <template v-if="!isCollapse">
                <div
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Назначение полиса
                    </div>
                    <div class="program-info-text">
                      <template v-if="subprogram.policyPurposes && subprogram.policyPurposes.length">
                        <div
                          v-for="purpose in subprogram.policyPurposes"
                          :key="purpose"
                        >
                          {{ policyPurposes[purpose] }}
                        </div>
                      </template>
                      <template v-else>
                        —
                      </template>
                    </div>
                  </div>
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Проект
                    </div>
                    <div class="program-info-text">
                      {{ projectType }}
                    </div>
                  </div>
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Особенности активации
                    </div>
                    <div class="program-info-text">
                      {{
                        subprogram.activationType !== null
                          ? activationTypes[subprogram.activationType]
                          : '—'
                      }}
                    </div>
                  </div>
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Вид программы
                    </div>
                    <div class="program-info-text">
                      {{
                        subprogram.subProgramType !== null
                          ? subProgramTypes[subprogram.subProgramType]
                          : '—'
                      }}
                    </div>
                  </div>
                  <div class="program-info-block">
                    <template v-if="subprogram.subProgramType === 1">
                      <div class="program-info-title">
                        Кол-во членов семьи
                      </div>
                      <div class="program-info-text">
                        {{ subprogram.familyMembersCount }}
                      </div>
                    </template>
                  </div>
                </div>

                <div class="program-info-row">
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Специальности врачей по телемедицине
                    </div>
                    <template v-if="getAllSelectedDutySpecializations.length > 0 || getAllSelectedNarrowSpecializations.length > 0">
                      <div
                        v-if="subprogram.isLimitation"
                        class="program-info-text"
                      >
                        Не ограничены любые дежурные врачи: {{ getAllSelectedDutySpecializationsSting }}<br>
                        Ограничено общее число консультаций к узкопрофильным специалистам.
                        <template v-if="!isAllSpecializationsSelected">
                          {{ subprogram.limitationCount }} консультаций к узкопрофильным специалистам:
                          {{
                            `${joinDoctorsArrayToString(getAllNarrowLimited)}${getAllNarrowNotLimited.length
                              ? `, в рамках оставшегося лимита - ${ joinDoctorsArrayToString(getAllNarrowNotLimited) }` : ''}`
                          }}
                        </template>
                        <template v-if="subprogram.limitationCount">
                          {{ subprogram.limitationCount }} консультаций к любым узкопрофильным специалистам
                        </template>
                      </div>
                      <div
                        v-else
                        class="program-info-text"
                      >
                        Не ограничены любые дежурные врачи: <br>
                        {{ getAllNarrowNotLimited.length > 0 ?
                          `${getAllSelectedDutySpecializationsSting}; Узкопрофильные специалисты: ${joinDoctorsArrayToString(getAllNarrowNotLimited)}`
                          : getAllSelectedDutySpecializationsSting
                        }}

                        <div v-if="getAllNarrowLimited.length > 0">
                          Ограничены: {{ joinDoctorsArrayToString(getAllNarrowLimited) }}
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="program-info-text"
                      >
                        —
                      </div>
                    </template>
                  </div>
                </div>

                <div
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Считать лимит телемедицинских консультаций.
                    </div>
                    <div
                      class="program-info-text"
                    >
                      {{ subprogram.isCountConsultationLimit ? 'Да' : 'Нет' }}
                    </div>
                  </div>
                </div>

                <div
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      С возможностью увеличения лимита
                    </div>
                    <div
                      class="program-info-text"
                    >
                      {{ subprogram.canChangeLimit ? 'Да' : 'Нет' }}
                    </div>
                  </div>
                </div>

                <div
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Узкопрофильные блоки
                    </div>
                    <div
                      v-if="subprogram.narrowSpecializations && subprogram.narrowSpecializations.length"
                      class="program-info-text"
                    >
                      {{ narrowSpecializations }}
                    </div>
                    <div
                      v-else
                      class="program-info-text"
                    >
                      —
                    </div>
                  </div>
                </div>

                <div
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Покрытие лабораторных и инструментальных исследований
                    </div>

                    <br>
                    <ClassicEditor
                      v-model="subprogram.researchCover"
                      class="custom-ck-class"
                      :disabled="true"
                    />
                  </div>
                </div>

                <div
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Лечение
                    </div>

                    <br>
                    <ClassicEditor
                      v-model="subprogram.therapy"
                      class="custom-ck-class"
                      :disabled="true"
                    />
                  </div>
                </div>

                <div
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Гарантийные письма
                    </div>

                    <br>
                    <ClassicEditor
                      v-model="subprogram.indemnityLetters"
                      class="custom-ck-class"
                      :disabled="true"
                    />
                  </div>
                </div>

                <div
                  v-if="false"
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Транспортные расходы
                    </div>
                    <div class="program-info-text">
                      {{ subprogram.fares !== null ? subprogram.fares : '—' }}
                    </div>
                  </div>
                </div>

                <div
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Текст для распознавания подпрограммы
                    </div>

                    <template v-if="subprogram.recognitions && subprogram.recognitions.length">
                      <div
                        v-for="(rec, i) in subprogram.recognitions"
                        :key="i"
                        class="program-info-text"
                      >
                        {{ rec }}
                      </div>
                    </template>
                    <div
                      v-else
                      class="program-info-text"
                    >
                      —
                    </div>
                  </div>
                </div>

                <div
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Канал реализации
                    </div>

                    <br>
                    <ClassicEditor
                      v-model="subprogram.channel"
                      class="custom-ck-class"
                      :disabled="true"
                    />
                  </div>
                </div>

                <div
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Описание программы
                    </div>

                    <br>
                    <ClassicEditor
                      v-model="subprogram.description"
                      class="custom-ck-class"
                      :disabled="true"
                    />
                  </div>
                </div>

                <div
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Документы
                    </div>
                    <div
                      v-if="subprogram.documentIds && subprogram.documentIds.length"
                      class="crm-file-list"
                    >
                      <base-file
                        v-for="document in documents"
                        :key="document.id"
                        :file="document"
                        file-api="File/"
                      />
                    </div>
                    <div
                      v-else
                      class="program-info-text"
                    >
                      —
                    </div>
                  </div>
                </div>

                <div
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Документ для клиентов
                    </div>
                    <div
                      v-if="subprogram.fileName && subprogram.fileKey"
                      class="crm-file-list"
                    >
                      <base-file
                        :file="{
                          title: subprogram.fileName,
                          guid: subprogram.fileKey,
                        }"
                        :is-show="false"
                        file-api="File/"
                      />
                    </div>
                    <div
                      v-else
                      class="program-info-text"
                    >
                      —
                    </div>
                  </div>
                </div>

                <div
                  class="program-info-row"
                >
                  <div class="program-info-block">
                    <div class="program-info-title">
                      Полезные ссылки
                    </div>
                    <div
                      v-if="subprogram.usefulLinks && subprogram.usefulLinks.length"
                      class="program-info-links"
                    >
                      <div
                        v-for="(item, index) in subprogram.usefulLinks"
                        :key="index"
                        class="program-info-link-item"
                      >
                        <a
                          :href="item.link"
                          target="_blank"
                          class="program-info-link"
                        >
                          {{ item.description || item.link }}
                        </a>
                      </div>
                    </div>
                    <div
                      v-else
                      class="program-info-text"
                    >
                      —
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="collapse-btn"
                @click="onToggleCollapse"
              >
                <icon-arrow-dropdown
                  class="crm-icon-arrow-down"
                  :class="{ reverse: !isCollapse }"
                />
              </div>
            </div>

            <insurance-subprogram-patients
              :subprogram-id="subprogram.id"
              class="patients-subprogram-patients"
            />
          </template>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import { mixinRoles } from '@/mixins';
import { PROJECT_TYPES } from '@/helpers/consts';

import InsuranceSubprogramPatients from '@/components/InsurancePrograms/InsuranceSubprogramPatients';

import IconArrowDropdown from 'assets/images/arrow-dropdown.svg';

import {
  BaseFile,
} from '@/components/base';

export default {
  name: 'InsuranceSubprogramDetail',
  page: {
    title: 'CRM Doctis - Страховая подпрограмма',
  },
  components: {
    Loading,
    IconArrowDropdown,
    InsuranceSubprogramPatients,
    BaseFile,
    ClassicEditor: () => import('@/components/common/CKEditor/ClassicEditorComponent.vue'),
  },
  mixins: [mixinRoles],
  data() {
    return {
      PROJECT_TYPES,
      specializations: [],
      isLoading: false,
      company: {},
      program: {},
      subprogram: {},
      documents: [],
      isCollapse: true,
      deactivationReasons: [
        'Программа не продается (обследование продолжается)',
        'Программа не активна (все полисы деактивированы)',
      ],
      activationTypes: {
        0: 'По номеру полиса',
        1: 'По страхователю',
        2: 'Автоматическая активация',
      },
      attachmentTypes: {
        0: 'Дмс',
        1: 'ОМС',
      },
      policyPurposes: {
        1: 'Телемедицина',
        2: 'Диагностика',
        3: 'Госпитализация',
        4: 'Лечение',
        5: 'Роды',
        6: 'Ведение беременности',
      },
      subProgramTypes: {
        0: 'Индивидуальная',
        1: 'Семейная',
      },
      periodTypes: [
        {
          type: 'day',
          text: 'день/дней',
        },
        {
          type: 'week',
          text: 'неделя/недель',
        },
        {
          type: 'month',
          text: 'месяц/месяцев',
        },
        {
          type: 'year',
          text: 'год/лет',
        },
      ],
      responsibledFields: [
        // {
        //   key: 'id',
        //   label: 'Id',
        // },
        {
          key: 'displayName',
          label: 'Имя',
        },
        {
          key: 'roleName',
          label: 'Роль',
        },
      ],
    };
  },
  computed: {
    deactivationReason() {
      return this.deactivationReasons[this.subprogram.reasonDeactivation];
    },
    companyId() {
      return this.$route.params.company;
    },
    programId() {
      return this.$route.params.program;
    },
    subprogramId() {
      return this.$route.params.subprogram;
    },
    consultationLimitText() {
      const word = this.isAllSpecializationsSelected ? 'любым' : 'выбранным';
      const value = this.subprogram.limitationCount || '∞';
      return `Общее число консультаций к ${word} специалистам: ${value}`;
    },

    isAllSpecializationsSelected() {
      return Object.keys(this.subprogram.specializations).length === this.specializations.length;
    },
    narrowSpecializations() {
      return this.subprogram.narrowSpecializations.map((spec, index) => {
        if (index > 0) {
          const text = `, ${spec}`;
          return text;
        }
        return spec;
      }).join('');
    },
    getAllSelectedSpecializationsSting() {
      const arr = [];
      Object.keys(this.subprogram.specializations).map((key) => {
        const doctor = this.specializations.find((s) => s.id === +key);
        arr.push({
          ...doctor,
          limit: this.subprogram.specializations[key],
        });
        return null;
      });
      return arr;
    },

    getAllSelectedDutySpecializations() {
      return this.getAllSelectedSpecializationsSting.filter((doctor) => doctor.name?.includes('Дежурный'));
    },

    getAllSelectedNarrowSpecializations() {
      return this.getAllSelectedSpecializationsSting.filter((doctor) => !doctor.name?.includes('Дежурный'));
    },

    getAllNarrowLimited() {
      return this.getAllSelectedNarrowSpecializations.filter((doctor) => doctor.limit !== 999);
    },

    getAllNarrowNotLimited() {
      return this.getAllSelectedNarrowSpecializations.filter((doctor) => doctor.limit === 999);
    },

    getAllSelectedDutySpecializationsSting() {
      const arr = this.getAllSelectedDutySpecializations;
      const doctorsNameString = arr.map((doctor) => doctor.name);
      return doctorsNameString.join(',\n');
    },
    // limitlessSpecializations() {
    //   const limitlessSpecializations = [];
    //   Object.keys(this.subprogram.specializations).map((key) => {
    //     const value = +this.subprogram.specializations[key];
    //     const { name } = this.specializations.find((s) => s.id === +key);
    //     if (value !== 999) {
    //       limitlessSpecializations.push(name);
    //     }
    //     return null;
    //   });

    //   if (!limitlessSpecializations.length) {
    //     return null;
    //   }

    //   const textSpecs = limitlessSpecializations.map((spec, index) => {
    //     if (index > 0) {
    //       const text = `, ${spec}`;
    //       return text;
    //     }
    //     return spec;
    //   }).join('');

    //   return textSpecs;
    // },
    // limitedSpecializations() {
    //   const limitedSpecializations = [];
    //   Object.keys(this.subprogram.specializations).map((key) => {
    //     const value = +this.subprogram.specializations[key];
    //     const { name } = this.specializations.find((s) => s.id === +key);
    //     // console.log(name);
    //     if (value !== 999) {
    //       limitedSpecializations.push({ name, value });
    //     }
    //     return null;
    //   });

    //   if (!limitedSpecializations.length) {
    //     return null;
    //   }

    //   const textSpecs = limitedSpecializations.map((spec, index) => {
    //     if (index > 0) {
    //       const text = `, ${spec.name} — ${spec.value}`;
    //       return text;
    //     }
    //     return `${spec.name} — ${spec.value}`;
    //   }).join('');

    //   return textSpecs;
    // },
    projectType() {
      return this.PROJECT_TYPES[this.subprogram.projectType]?.title || '—';
    },
  },
  async created() {
    await this.orCreate();
  },
  methods: {
    async orCreate() {
      this.isLoading = true;

      try {
        // console.log('this.subprogramId', this.subprogramId);
        const { company, program, subProgram } = await this.$store.dispatch(this.$types.SUBPROGRAM_FETCH, this.subprogramId);
        const specializations = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, '');
        this.specializations = specializations;
        this.company = company;
        this.program = program;
        this.subprogram = { ...subProgram };
        // console.log('this.subprogram', this.subprogram);
        if (this.subprogram.documentIds) {
          const documentsName = await Promise.all(this.subprogram.documentIds
            .map((docId) => this.$store.dispatch(this.$types.FILENAME_BY_ID_FETCH, docId)));
          this.documents = documentsName.map((title, i) => ({ title, guid: this.subprogram.documentIds[i] }));
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    joinDoctorsArrayToString(arr) {
      // const arr = this.getAllSelectedNarrowSpecializations;
      const doctorsNameArr = arr.map((doctor) => {
        const str = doctor.limit !== 999 ? `${doctor.name} — ${doctor.limit}` : doctor.name;
        return str;
      });
      return doctorsNameArr.join(', ');
    },
    onToggleCollapse() {
      this.isCollapse = !this.isCollapse;
      console.log(this.isCollapse);
    },
    async hideOrShowProgram() {
      await this.$store.dispatch(this.$types.SUBPROGRAM_HIDE_OR_SHOW, {
        params: { subprogramId: this.subprogram.id },
      });

      await this.orCreate();
    },
    hideOrShowAgreeModal() {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: { cb: this.hideOrShowProgram },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-company {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.insurance-company-inner {
  padding: 30px 0;
}

.crm-container {
  flex-direction: column;
}
.crm-container-title {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.crm-title {
  @extend .page-title;
  display: flex;
  align-items: center;

  span {
    margin-left: 15px;
  }
}

::v-deep.crm-title-link {
  line-height: 0;
  cursor: pointer;

  .arrow-left {
    rect {
      stroke: #6E88F3;
    }
    path {
      fill: $blue;
    }
  }
}

::v-deep.crm-icon {

  &:not(last-child) {
    margin-bottom: 15px;
  }

  &:hover {
    cursor: pointer;

    rect {
      stroke: #6E88F3;
    }
    path {
      fill: $blue;
    }
  }
}
.program-detail {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 45px;
  position: relative;
  box-sizing: border-box;
}

.crm-title-row {
  display: flex;
  justify-content: space-between;
}

.program-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-right: 30px;
  display: flex;
}

.program-info-row {
  display: flex;
  margin-top: 25px;
}
.deactivation-info {
  margin-top: 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: $black-light;
  span {
    color: $gray-dark;
  }
}
.program-info-block {
  flex: 1;

  & + & {
    margin-left: 30px;
  }
}

.program-info-title {
  font-size: 14px;
  line-height: 18px;
  color: #707070;
}

.program-info-text {
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  white-space: pre-line;
}

.program-info-documents {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.program-info-document {
  display: flex;
  align-items: center;
  margin: 15px 15px 0;
  width: calc( 100% / 4 - 30px );
}

.program-info-document-text {
  margin: 0 10px;
}

.crm-icon-close {
  cursor: pointer;
}

.crm-icon-close, .crm-icon-letter {
  flex-shrink: 0;
}

.program-info-links {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.program-info-link-item {
  margin: 15px 15px 0;
  width: calc( 100% / 4 - 30px );
}

.program-info-link {
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6E88F3;
}

.collapse-btn {
  background: #e2e6ea;
  border-radius: 0 0 4px 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.crm-icon-arrow-down {
  path {
    fill: #007bff;
  }

  &.reverse{
    transform: rotate(180deg);
  }
}

.closed-label {
  color: #EB5757;
  background-color: #FFDCDC;
  border-radius: 20px;
  width: 100px;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  margin-left: 10px;
}

.patients-subprogram-patients {
  margin-top: 25px;
}

::v-deep.crm-file-list {
  margin: 0 -10px 30px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  .file {
    margin: 10px;
    max-width: calc(25% - 20px);
    width: auto;
    word-break: break-word;
  }
}
</style>
